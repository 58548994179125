import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Contact = () => (
  <>
    <SEO title="Contact" />
    <Layout />
  </>
);
export default Contact;
